<template>
  <div class="dx-viewport sx-scrollable">
    <div
      v-on:scroll.passive="sxScrollHandle"
      :id="String.format('{0}-double-scroll-wrapper', gridId)"
      :class="{
        hidden: !isDoubleScrollNeeded || $isMobile(),
      }"
      class="sx-double-scroll-wrapper"
    >
      <div class="double-scroll"></div>
    </div>
    <DxDataGrid
      :ref="gridRefName"
      :id="gridId"
      :data-source="dataSource"
      :word-wrap-enabled="true"
      :allow-column-reordering="!$isMobile()"
      :show-column-lines="true"
      :show-row-lines="true"
      :show-borders="true"
      :row-alternation-enabled="true"
      :allow-column-resizing="true"
      :column-resizing-mode="currentResizingMode"
      :column-hiding-enabled="true"
      :column-auto-width="true"
      :focused-row-enabled="false"
      :hover-state-enabled="true"
      :remote-operations="true"
      :cache-enabled="true"
      :noDataText="
        $t(
          'BaseModelFields.NoRecords',
          {},
          { locale: this.$store.state.activeLang }
        )
      "
      :selection="{ mode: 'single' }"
      @selection-changed="selectedChanged"
      @rowDblClick="rowDblClick"
      @row-prepared="onRowPrepared"
      @cell-prepared="onCellPrepared"
      @initialized="initialized"
      @contentReady="contentReady"
      @exporting="onExporting"
      @option-changed="onOptionChanged"
    >
      <DxExport :enabled="settings.allowExcelExport" />
      <DxColumnChooser :enabled="true" mode="select">
        <DxPosition
          my="right top"
          at="right bottom"
          of=".dx-datagrid-column-chooser-button" />
        <DxColumnChooserSearch :enabled="true"
      /></DxColumnChooser>

      <DxEditing
        :allow-updating="false"
        :allow-deleting="settings.allowDeleting"
        :allow-adding="false"
        mode="row"
      >
        <DxTexts confirm-delete-message="" />
      </DxEditing>

      <DxToolbar>
        <DxItem location="after" template="fontSizeChangerTemplate" />
        <DxItem name="exportButton" />
        <DxItem name="columnChooserButton" />
        <DxItem name="searchPanel" />
      </DxToolbar>

      <!-- Use Json Viewer Pages Template -->
      <template #jsonViewerTemplate="{ data: { data } }">
        <div
          class="text-center"
          v-if="
            data.logType == 'FieldServiceCreateRecord' ||
            data.logType == 'FieldServiceUpdateRecord' ||
            data.logType == 'FieldServiceUpdateFieldValue'
          "
        >
          <button
            type="button"
            class="btn btn-warning"
            @click="onHistoryDataViewButtonClicked(data)"
          >
            <i class="bi bi-filetype-json me-2"></i>
            {{
              $t(
                "History.Information",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
        </div>
      </template>
      <!-- Use Json Viewer Pages Template -->

      <!-- Difference Between Two Records Template -->
      <template #differenceBetweenTwoRecordsTemplate="{ data: { data } }">
        <div
          class="text-center"
          v-if="data.logType === 'FieldServiceUpdateRecord'"
        >
          <button
            type="button"
            class="btn btn-primary"
            @click="onDifferenceBetweenTwoRecordButtonClick($event, data)"
          >
            <i class="bi bi-filetype-json me-2"></i>
            {{
              $t(
                "History.DifferenceBetweenTwoRecords",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
        </div>
      </template>
      <!-- Difference Between Two Records Template -->

      <template #fontSizeChangerTemplate>
        <DxButtonToolbar icon="fontsize" @click="fontSizeChange" />
      </template>

      <DxColumn
        v-for="(item, i) in fixedColumns"
        :key="i"
        :width="item.width"
        :data-field="item.field"
        :data-type="item.type"
        :caption="item.text"
        :cell-template="item.template"
        :format="item.format"
        :alignment="item.textAlign"
        v-model:visible="item.visible"
      />

      <DxColumn
        v-for="(item, i) in columns"
        :key="i"
        :width="item.width > 0 ? item.width : 'auto'"
        :minWidth="
          !String.isNullOrWhiteSpace(item.minWidth)
            ? item.minWidth
            : this.gridColumnDefaultMinWidth
        "
        :data-field="item.field"
        :data-type="item.type"
        :caption="item.text"
        :cell-template="item.template"
        :format="item.format"
        :alignment="item.textAlign"
        v-model:visible="item.visible"
      />

      <template #recordLogActionTypeTemplate="{ data: { data } }">
        <span class="">
          {{
            $t(
              "History." + data.logType,
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </span>
      </template>

      <DxColumn
        v-if="!settings.isCommandColumnHide"
        type="buttons"
        :allow-sorting="false"
        :caption="
          this.$t(
            'Buttons.Actions',
            {},
            { locale: this.$store.state.activeLang }
          )
        "
      >
        <template v-if="settings.buttons">
          <DxButton
            @click="actionButtonClick($event, item)"
            v-for="(item, i) in settings.buttons"
            :key="i"
          >
            <template #default>
              <button
                type="button"
                class="btn btn-sm btn-no-custom-style"
                :class="item.cssClass"
                v-bind="item.attributes"
              >
                <i
                  v-if="
                    !String.isNullOrWhiteSpace(item.iconClass) &&
                    !item.iconClass.includes('sx')
                  "
                  class="bi"
                  :class="item.iconClass"
                ></i>
                <span v-else :class="item.iconClass">&nbsp;</span>
              </button>
            </template>
          </DxButton>
        </template>
      </DxColumn>
      <template #notificationStatusTypeTemplate="{ data: { data } }">
        <tr class="main-row">
          <td rowspan="2" style="text-align: center">
            <button
              type="button"
              class="btn button-grow"
              @click="statusChange($event, data)"
              :value="data.statusTypeId"
            >
              <i
                v-if="data.statusTypeId == 1"
                class="fs-1 far bi bi-envelope-fill fa-2x text-secondary"
              ></i>
              <i
                v-else
                class="fs-1 bi bi-envelope-open-fill fa-2x text-success"
              ></i>
            </button>
          </td>
        </tr>
      </template>
      <template #notificationTypeTemplate="{ data: { data } }">
        <tr class="main-row text-color-notificationSubject">
          <td rowspan="1">{{ data.notificationSubject }}</td>
        </tr>
        <tr class="notes-row">
          <td colspan="5">
            <div
              v-html="data.notificationMessage"
              @click="statusChange($event, data)"
              v-on:click.middle="statusChange($event, data)"
            ></div>
          </td>
        </tr>
      </template>
      <DxSearchPanel
        v-if="settings.allowSearchPanel"
        :visible="true"
        :highlight-case-sensitive="true"
        :search-visible-columns-only="true"
      />
      <DxPager
        :visible="settings.allowPaging"
        :allowed-page-sizes="pageSizes"
        :show-page-size-selector="settings.allowPaging"
        :show-navigation-buttons="settings.allowPaging"
        :show-info="settings.allowPaging"
      />
      <DxPaging :visible="settings.allowPaging" :page-size="pageSize" />
    </DxDataGrid>
  </div>
  <JsonViewerModal :json="previewJsonData" />
</template>
<script>
import DxDataGrid, {
  DxColumn,
  DxEditing,
  DxPaging,
  DxColumnChooser,
  DxPosition,
  DxColumnChooserSearch,
  DxSearchPanel,
  DxToolbar,
  DxItem,
  DxPager,
  DxButton,
  DxTexts,
  DxExport,
} from "devextreme-vue/data-grid";

import DxButtonToolbar from "devextreme-vue/button";

import CustomStore from "devextreme/data/custom_store";

//excel export library
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import saveAs from "file-saver";

import $ from "jquery";
// import { createToast } from "mosha-vue-toastify";
// import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default {
  name: "DevExpressStaticGrid",
  props: {
    settings: {
      type: Object,
      default() {
        return {};
      },
      information: {
        action: "Example",
        requestUrl: "/Example/List?staticProperty=1", //or "/Example/List"
        buttons: [
          {
            name: "", // button name
            cssClass: "", //button classes in bootstrap 5 or custom classes in assets stylesheets
            iconClass: "bi-", //i classes in bootstrap 5 icons or custom classes in assets stylesheets
            routeButton: false, // router push?
            emitMethodName: "onJsonViewerButtonClick", //parent emit method name
            attributes: {
              //example
              "data-bs-toggle": "modal",
              "data-bs-target": "#modalJsonViewer",
            },
          },
          {
            name: "",
            cssClass: "",
            iconClass: "bi-",
            routeButton: true, //on router push and router object is required
            routeObj: {
              name: "CustomObjectEditButton", //name in router.js
              params: {
                customObjectId: "",
                buttonId: "",
              },
            },
          },
        ],

        httpRequestType: "GET",
        isPublicIdColumnHide: false,
        isCommandColumnHide: false,
        allowSearchPanel: true,
        allowExcelExport: false,
        allowPaging: false,
        autoOrderProcess: false,
      },
    },
    columns: {
      type: Array,
      default: () => [],
      required: true,
      information: {
        text: "string", //column localize caption
        field: "string", //row data in field
        type: "string", //'string' | 'number' | 'date' | 'boolean' | 'object' | 'datetime'
        visible: "boolean", //column is visible
        width: "number", //column width value
        minWidth: "number", //column min width value
        // maxWidth: "number", //column max width value !! not supported
        template: "string", //column template
        format: "string", //column data view format
        textAlign: "string", //'left' | 'center' | 'right'
      },
    },
    //!! local data binding
    //this prop for this to work, the request url parameter in the settings object must be empty.
    rows: {
      type: Array,
      default: () => [],
      required: false,
    },
    pageNumberStartFrom: {
      type: Number,
      default: 1,
    },
  },
  emits: [
    //base emits
    "onRequestFinally", // fire when the request is finished
    "onReturnRequestResponse", //fire when the request returns
    "onRequestUnSuccessful", // fire if the request fails (isOk:false)

    //history page emit
    "onCompareRecords",
    "onHistoryRecordDataView",
  ],
  components: {
    DxDataGrid,
    DxColumn,
    DxPosition,
    DxColumnChooserSearch,
    DxEditing,
    DxPaging,
    DxButton,
    DxToolbar,
    DxItem,
    DxColumnChooser,
    DxSearchPanel,
    DxPager,
    DxTexts,
    DxExport,
    DxButtonToolbar,
  },
  data() {
    return {
      isDoubleScrollNeeded: false,
      previewJsonData: null,
      gridId: `grid_${this.settings.action}`,
      gridColumnDefaultMinWidth: 100,
      fixedColumns: [
        {
          text: this.$t(
            "BaseModelFields.SerialNo",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "displayOrder",
          type: "string",
          visible: !this.$isMobile(),
          width: 50,
          template: "",
          format: "",
          textAlign: "center",
        },
      ],
      publicIdColumn: {
        text: this.$t(
          "BaseModelFields.RecordPublicId",
          {},
          { locale: this.$store.state.activeLang }
        ),
        field: "publicId",
        type: "string",
        visible: false,
        width: 350,
        template: "",
        format: "",
        textAlign: "",
      },
      gridRefName: "grid",
      currentResizingMode: this.$isMobile() ? "nextColumn" : "widget",
      pageSizes: [10, 25, 50, 100, 500],
      pageSize: this.settings.allowPaging ? 50 : 500,
      defaultFontSize: 14,
      maxFontSize: 20,
      selectedRowIndex: -1,
      dataSource: null,
      primaryKey: "publicId",
    };
  },
  computed: {
    grid() {
      return this.$refs[this.gridRefName]
        ? this.$refs[this.gridRefName].instance
        : null;
    },
  },
  created() {
    if (!String.isNullOrWhiteSpace(this.settings.requestUrl)) {
      this.getData();
    } else if (this.rows.length > 0) {
      //local data
      this.dataSource = this.rows;
    }

    if (!this.settings.isPublicIdColumnHide) {
      this.fixedColumns.push(this.publicIdColumn);
    }
  },
  mounted() {
    if (!this.$isMobile()) {
      var self = this;
      $(window)
        .off("scroll")
        .on("scroll", function () {
          self.onWindowScrolling();
        });
    }
  },
  watch: {
    "settings.requestUrl": {
      deep: true,
      handler() {
        if (!String.isNullOrWhiteSpace(this.settings.requestUrl)) {
          this.getData();
        }
      },
    },
    rows: {
      deep: true,
      handler() {
        this.dataSource = this.rows;
        this.grid.refresh();
      },
    },
  },
  methods: {
    onDifferenceBetweenTwoRecordButtonClick($event, data) {
      var items = this.getDataSourceItems(),
        allFields = this.$parent.allFields;
      if (!items || !data) return;

      items = items.filter(
        (f) =>
          f.logType === "FieldServiceUpdateRecord" ||
          f.logType === "FieldServiceCreateRecord"
      );

      var currentItem = items.find((f) => f.publicId == data.publicId);
      if (!currentItem) return;

      var currentItemIndex = items.indexOf(currentItem),
        compareRecord = items[currentItemIndex + 1];

      if (!compareRecord) return;

      var currentItemRecordDataLog = currentItem.recordData;
      if (!String.isNullOrWhiteSpace(currentItemRecordDataLog)) {
        currentItemRecordDataLog = JSON.parse(currentItemRecordDataLog);
      }

      var compareRecordDataLog = compareRecord.recordData;
      if (!String.isNullOrWhiteSpace(compareRecordDataLog)) {
        compareRecordDataLog = JSON.parse(compareRecordDataLog);
      }

      var historyCompareRecords = [];
      currentItemRecordDataLog.forEach((log) => {
        var oldLog = compareRecordDataLog.find((f) => f.Key === log.Key),
          currentField = allFields.find((f) => f.formulaName === log.Key),
          oldValue = "",
          fieldName = log.Key;

        if (currentField) {
          if (this.$store.state.isMultiLanguage) {
            var fieldLocalizationValue =
              this.$store.getters.getLocalizationValuesByParameters({
                parentId: currentField.customObjectPublicId,
                itemFormulaName: currentField.formulaName,
                itemTypeId: 2,
              });
            if (fieldLocalizationValue) {
              fieldName = fieldLocalizationValue.value;
            } else {
              fieldName = currentField.name;
            }
          } else {
            fieldName = currentField.name;
          }
        }

        if (!String.isNullOrWhiteSpace(oldLog)) {
          oldValue = oldLog.Value;
        }

        if (log.Value !== oldValue) {
          historyCompareRecords.push({
            key: fieldName,
            value: log.Value,
            oldValue: oldValue,
          });
        }
      });

      historyCompareRecords = historyCompareRecords.filter(
        (f) => !f.key.includes(this.$fieldPublicIdFormulaExtension)
      );

      this.$emit("onCompareRecords", historyCompareRecords);
    },
    onHistoryDataViewButtonClicked(data) {
      var recordData = data.recordData,
        allFields = this.$parent.allFields,
        modifiedItems = [];
      if (!String.isNullOrWhiteSpace(recordData)) {
        recordData = JSON.parse(recordData);
        recordData.forEach((recordDataItem) => {
          var fieldName = recordDataItem.Key,
            currentField = allFields.find(
              (f) => f.formulaName === recordDataItem.Key
            );

          if (currentField) {
            if (this.$store.state.isMultiLanguage) {
              var fieldLocalizationValue =
                this.$store.getters.getLocalizationValuesByParameters({
                  parentId: currentField.customObjectPublicId,
                  itemFormulaName: currentField.formulaName,
                  itemTypeId: 2,
                });
              if (fieldLocalizationValue) {
                fieldName = fieldLocalizationValue.value;
              } else {
                fieldName = currentField.name;
              }
            } else {
              fieldName = currentField.name;
            }
          }

          modifiedItems.push({
            key: fieldName,
            value: recordDataItem.Value,
          });
        });
      }

      modifiedItems = modifiedItems.filter(
        (f) => !f.key.includes(this.$fieldPublicIdFormulaExtension)
      );

      this.$emit("onHistoryRecordDataView", modifiedItems);
    },
    getDataSourceItems() {
      return this.grid.getDataSource()._items;
    },
    onJsonViewerButtonClick($event, data) {
      var columnFormulaName = $($event.target)
        .closest("td")
        .data("columnformulaname");
      var jsonData = String.isNullOrWhiteSpace(columnFormulaName)
        ? null
        : data[columnFormulaName];

      if (!String.isNullOrWhiteSpace(jsonData))
        this.previewJsonData = JSON.parse(jsonData);
    },
    fontSizeChange(e) {
      var fontSize = ++this.currentFontSize;
      var realFontSize = this.$root.gridFontSizeChanger(this, fontSize);
      Object.setCookie("SxGridFontSize", realFontSize);
    },
    initialized(e) {
      //ui before
    },
    contentReady(e) {
      //ui after

      var fontSizeCookie = Object.readCookie("SxGridFontSize");
      if (!String.isNullOrWhiteSpace(fontSizeCookie))
        this.$root.gridFontSizeChanger(this, fontSizeCookie);

      this.configurationSxScroll();
    },
    configurationSxScroll() {
      var contentScroll = $(
          String.format("#{0} .dx-datagrid-content:first", this.gridId)
        )[0],
        sxScroll = $(
          String.format(
            "#{0}-double-scroll-wrapper .double-scroll",
            this.gridId
          )
        );

      if (!contentScroll || !sxScroll) return;

      this.isDoubleScrollNeeded =
        contentScroll.scrollWidth > contentScroll.clientWidth;

      sxScroll.width(contentScroll.scrollWidth);
    },
    sxScrollHandle() {
      var dxScroll = this.grid.getScrollable(),
        customScrollLeftMargin = $(
          String.format("#{0}-double-scroll-wrapper", this.gridId)
        ).scrollLeft();

      dxScroll.scrollTo({ left: customScrollLeftMargin });
    },
    onWindowScrolling() {
      var scroll = $(String.format("#{0}-double-scroll-wrapper", this.gridId)),
        grid = $(String.format("#{0}", this.gridId)),
        sxHeader = $("#sx_header"),
        sxToolbar = $("#sx_toolbar"),
        isSxToolbarSticky = sxToolbar.css("position") == "sticky",
        headerPanel = grid.find(".dx-datagrid-header-panel"),
        header = grid.find(".dx-datagrid-headers"),
        isWindowScrolling = $(window).scrollTop() > 0,
        topValues = {
          scroll: 0,
          header: 0,
          headerPanel: 0,
        },
        opacity = 1;

      if (isWindowScrolling) {
        opacity = 0.7;
        if (isSxToolbarSticky && sxToolbar.length > 0) {
          topValues.scroll = sxToolbar.outerHeight();
          topValues.header = sxToolbar.outerHeight();
          topValues.headerPanel = sxToolbar.outerHeight();
        }

        if (sxHeader.length > 0) {
          topValues.scroll += sxHeader.outerHeight();
          topValues.header += sxHeader.outerHeight();
          topValues.headerPanel += sxHeader.outerHeight();
        }

        if (headerPanel.length > 0) {
          topValues.scroll += headerPanel.outerHeight();
          topValues.header += headerPanel.outerHeight();
        }

        if (header.length > 0) {
          topValues.scroll += header.outerHeight();
        }
      }

      header.css({
        top: topValues.header,
      });

      headerPanel.css({
        top: topValues.headerPanel,
      });

      scroll.css({
        top: topValues.scroll,
        opacity: opacity,
      });
    },
    onExporting(e) {
      var excelFileName = `${this.settings.action}.xlsx`;
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet(
        this.$t(
          "BaseModelFields.MainSheet",
          {},
          { locale: this.$store.state.activeLang }
        )
      );

      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        customizeCell: function (options) {
          options.excelCell.font = { name: "Arial", size: 12 };
          options.excelCell.alignment = { horizontal: "left" };
        },
      }).then(function () {
        workbook.xlsx.writeBuffer().then(function (buffer) {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            excelFileName
          );
        });
      });
      e.cancel = true;
    },
    actionButtonClick($event, button) {
      var rowData = $event.row.data;
      if (button.routeButton) {
        var routeObj = button.routeObj;
        this.settings.routeRequiredParameters.forEach((element) => {
          if (element.isRouteParameter) {
            routeObj.params[element.key] = this.$route.params[element.value];
          } else {
            routeObj.params[element.key] = rowData[element.value];
          }
        });

        this.$router.push(routeObj);
      } else if (!String.isNullOrWhiteSpace(button.emitMethodName)) {
        this.$emit(button.emitMethodName, rowData, $event, this.dataSource);
      }
    },
    onRowPrepared(e) {
      if (e.rowType == "data") {
        e.rowElement.classList.add("sx-item");
        if ("publicId" in e.data) {
          e.rowElement.setAttribute("data-id", e.data.publicId);
        }
      }
    },
    onCellPrepared(args) {
      if (!String.isNullOrWhiteSpace(args.column.dataField)) {
        String.SetAttributes(args.cellElement, {
          "data-columnformulaname": args.column.dataField,
        });
      }

      if (args.rowType === "data") {
        var displayOrderVisibleIndex =
          this.grid.getVisibleColumnIndex("displayOrder");
        if (displayOrderVisibleIndex !== -1) {
          if (args.columnIndex == displayOrderVisibleIndex) {
            var serialNumberColumn = args.cellElement,
              rowIndex = ++args.rowIndex,
              page = args.component.pageIndex(),
              pageSize = args.component.pageSize(),
              startIndex = page * pageSize,
              text = "";

            if (this.settings.allowPaging) {
              text = startIndex + rowIndex;
            } else {
              text = rowIndex;
            }

            if (!this.settings.autoOrderProcess) {
              text = args.data["displayOrder"];
            }

            if (!String.isNullOrWhiteSpace(this.settings.serialNoLink)) {
              serialNumberColumn.innerHTML = `<a class="text-decoration-none" href="${
                this.settings.serialNoLink
              }${args.data[this.primaryKey]}">${text}</a>`;
            } else {
              serialNumberColumn.innerText = text;
            }
          }
        }

        if (this.settings.action == "ImportBulkRecordDataList") {
          if (
            "status" in args.data &&
            args.column.type == "buttons" &&
            (args.data.status == "Waiting" ||
              String.isNullOrWhiteSpace(args.data.status))
          ) {
            $(args.cellElement).html("");
          }
        }
      }
    },
    selectedChanged(e) {
      this.selectedRowIndex = e.component.getRowIndexByKey(
        e.selectedRowKeys[0]
      );
    },
    getData() {
      var settings = this.settings,
        route = this.$route,
        appAxios = this.$appAxios,
        self = this;

      this.dataSource = new CustomStore({
        key: "",
        load(loadOptions) {
          var page = $(String.format("#grid_{0}", settings.action))
              .find(".dx-page.dx-selection")
              .text(),
            reqUrl = settings.requestUrl,
            requestParameters = [];

          if (!String.isNullOrWhiteSpace(settings.requestUrlRouteParamNames)) {
            settings.requestUrlRouteParamNames.forEach((element) => {
              requestParameters.push({
                key: element.key,
                value: route.params[element.value],
              });
            });
          }

          if (loadOptions.filter) {
            requestParameters.push({
              key: "q",
              value: loadOptions.filter[0].filterValue,
            });
          }

          if (settings.allowPaging) {
            if (self.pageNumberStartFrom == 0) {
              page =
                parseInt(
                  String.isNullOrWhiteSpace(page)
                    ? self.pageNumberStartFrom
                    : page
                ) - 1;
            } else if (String.isNullOrWhiteSpace(page)) {
              page = loadOptions.skip == 0 ? 1 : loadOptions.skip;
            }
            var pageSize = self.pageSize;
            if (!String.isNullOrWhiteSpace(loadOptions.take)) {
              pageSize = loadOptions.take;
            }
            requestParameters.push({ key: "page", value: page });
            requestParameters.push({ key: "size", value: pageSize });
          }

          if (requestParameters.length > 0) {
            requestParameters.forEach((element) => {
              var separator = reqUrl.includes("?") ? "&" : "?";
              reqUrl += `${separator}${element.key}=${element.value}`;
            });
          }

          var instance = appAxios;

          var httpRequest =
            settings.httpRequestType === "POST"
              ? instance.post(reqUrl)
              : instance.get(reqUrl);

          return httpRequest
            .then((response) => {
              var result = response.data;
              if ("isOk" in result && "items" in result) {
                if (!result.isOk) {
                  self.$emit("onRequestUnSuccessful", result);
                }

                //modify item count
                if ("itemCount" in result) {
                  //
                } else {
                  response.data.itemCount = result.items.length;
                }
              } else {
                //modify data object
                response = {
                  data: {
                    items: result,
                    itemCount: result.length,
                  },
                };
              }

              self.$emit("onReturnRequestResponse", response);

              return response;
            })
            .then((data) => ({
              data: data.data.items,
              totalCount: data.data.itemCount,
            }))
            .catch(function (error) {
              self.$emit("onRequestUnSuccessful", error);

              //TODO: console total count error fix
              return Promise.resolve({
                data: [],
                totalCount: 0,
              });
            })
            .finally(() => {
              self.$emit("onRequestFinally");
            });
        },
      });
    },
    rowDblClick(e) {
      //
    },
    onOptionChanged(e) {
      if (e.name === "columns" && e.fullName.endsWith("width")) {
        this.configurationSxScroll();
      }
    },
    statusChange($event, data) {
      var items = this.getDataSourceItems();
      if (!items || !data) return;

      var currentItem = items.find((f) => f.publicId == data.publicId);
      if (!currentItem) return;

      var currentItemIndex = items.indexOf(currentItem),
        model = {
          publicId: currentItem.publicId,
        };
      if (currentItem.statusTypeId == 1) {
        this.$appAxios
          .post(`rws-NotificationController-SetRead`, model)
          .then((response) => {
            if (response.data.isOk) {
              this.grid.refresh();
            }
          });
      } else {
        this.$appAxios
          .post(`rws-NotificationController-SetUnRead`, items[currentItemIndex])
          .then((response) => {
            if (response.data.isOk) {
              this.grid.refresh();
            }
          });
      }
    },
  },
};
</script>
<style scoped>
.button-grow {
  background-color: transparent;
  border-radius: 4px;
  padding: 0 16px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.button-grow:hover {
  transform: scale(1.1);
}
.text-color-notificationSubject {
  color: #4d4d4d;
  font-weight: 600;
}
</style>
